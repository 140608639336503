@import "./reset.scss";
@import "./fonts.scss";
@import "./mixins.scss";
@import "./theme-definitions.scss";

html,
body,
#root {
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 100%;
  overflow: overlay;
  white-space: pre-line;
  @include themify-global($themes) {
    // background-color: themed("root-bg");
  }
}

.ReactModal__Overlay {
  z-index: 1000;
}

* {
  box-sizing: border-box;
}
